import tipos from '@/store/tipos'

export default {
    state: {
        chatSelecionado: null,
        deveNavegarAteMensagem: null,
        chatMsgs: [],
    },
    mutations: {
        [tipos.SET_CHAT](state, chat) {
            state.chatSelecionado = chat
        },
        [tipos.SET_DEVE_NAVEGAR_ATE_MENSAGEM](state, msg) {
            state.deveNavegarAteMensagem = msg
        },
        [tipos.SET_CHAT_MSGS](state, msgs) {
            state.chatMsgs = msgs
        },
    },
    actions: {
        [tipos.SET_CHAT]({ commit }, chat) {
            commit(tipos.SET_CHAT, chat);
        },
        [tipos.SET_DEVE_NAVEGAR_ATE_MENSAGEM]({ commit }, msg) {
            commit(tipos.SET_DEVE_NAVEGAR_ATE_MENSAGEM, msg);
        },
        [tipos.CLEAN_DEVE_NAVEGAR_ATE_MENSAGEM]({ commit }) {
            commit(tipos.SET_DEVE_NAVEGAR_ATE_MENSAGEM, null);
        },
        [tipos.SET_CHAT_MSGS]({ commit }, msgs) {
            commit(tipos.SET_CHAT_MSGS, msgs);
        },
        [tipos.CLEAN_CHAT_MSGS]({ commit }) {
            commit(tipos.SET_CHAT_MSGS, []);
        },
    },
    getters: {
        chatAtual: state => state.chatSelecionado,
        deveNavegarAteMensagem: state => state.deveNavegarAteMensagem,
        chatMsgs: state => state.chatMsgs
    }
}