<template>
  <div>
    <!-- A opção noOverlay é ativada porque nós implementamos o efeito do overlay
    manualmente, por conta da funcionalidade incorreta do overlay do slide  -->
    <Slide
      noOverlay
      @openMenu="isOpen = true"
      @closeMenu="isOpen = false"
      ref="slide"
      :closeOnNavigation="true"
      :class="['slide', isOpen ? 'back-overlay' : '']"
    >
      <div class="menu-header">
        <div class="user-info">
          <el-avatar icon="el-icon-user-solid av" />
          <span style="color: #fff">{{ nomeUsuarioLogado }}</span>
        </div>
      </div>
      <a class="menu-link" @click="$router.push({name:'Mensagens'})">
        <i class="fas fa-comment-alt" />
        <span>Chat</span>
      </a>
      <a class="menu-link" @click="$router.push({name:'Calendario'});">
        <i class="fas fa-calendar-alt c" />
        <span>Calendário</span>
      </a>
      <a class="menu-link" href="/login" @click="() => $emit('logout')"
        ><span>Sair</span></a
      >
    </Slide>
    <span style="color: #03283f">{{ nomeComponente }}</span>
  </div>
</template>

<script>
import { Slide } from "vue-burger-menu";
export default {
  props: ["nomeUsuarioLogado", "nomeComponente"],
  components: {
    Slide,
  },
  data() {
    return {
      isOpen: false,
    };
  },
};
</script>

<style scoped>
.user-info {
  display: flex;
  align-items: center;
}

.user-info *:last-child {
  margin-left: 10px;
  max-width: 200px;
  word-break: keep-all;
  text-align: left;
}

.user-info span {
  margin-top: 10px;
  color: #fff;
}

.menu-link {
  padding-left: 29px;
}



.menu-link span {
  color: var(--primeira-cor) !important;
  font-size: 15px;
  font-weight: 600;
}

.back-overlay::before {
  content: "";
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  z-index: 100;
}

@media (max-width: 1000px) {
  .monitor {
    display: none;
  }
  .mobile {
    display: show;
  }
  .el-aside {
    display: none;
  }
}
@media (min-width: 1001px) {
  .monitor {
    display: show;
  }
  .mobile {
    display: none;
  }
  .el-aside {
    display: show;
  }
}
</style>
