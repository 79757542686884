import AuthUtils from "@/utils/AuthUtils";

const webapps = {
    KLAUS_WEBAPP: {
        id: "KLAUS_WEBAPP",
        baseUrl: process.env.VUE_APP_WEBAPP_KLAUS_WEBAPP,
    },
    KMSX_PWA: {
        id: "KMSX_PWA",
        baseUrl: process.env.VUE_APP_WEBAPP_KMSX_PWA,
    },
    OUTROS: {
        id: "OUTROS",
        baseUrl: '',
    }
}

class RecursoKlaus {
    descricao = "Recurso da Klaus";
    roles = [
        AuthUtils.roles.ADMINISTRADOR,
        AuthUtils.roles.ORGANIZACAO,
        AuthUtils.roles.USUARIO_ADM,
        AuthUtils.roles.USUARIO_LIMITADO,
        AuthUtils.roles.CLIENTE,
        AuthUtils.roles.DESENVOLVEDOR
    ];
    icone = "";

    constructor(nome, id, isAmbiente, exibirNaListaDeRecursos, webapp, rota, icone = null, descricao = null, roles = null) {
        this.nome = nome;
        this.id = id;
        this.isAmbiente = isAmbiente;
        this.exibirNaListaDeRecursos = exibirNaListaDeRecursos;
        this.webapp = webapp;
        this.rota = rota;
        this.icone = icone || this.icone;
        this.descricao = descricao || this.descricao;
        this.roles = roles || this.roles;
    }
}

const recursos = {
    HOME: new RecursoKlaus("Home", "HOME",
        true,
        true,
        webapps.KLAUS_WEBAPP,
        "/",
        "home-icon.svg"),

    FISCAL: new RecursoKlaus("Fiscal", "FISCAL",
        true,
        true,
        webapps.KLAUS_WEBAPP,
        "/fiscal",
        "fiscal-icon.svg",
        "Tenha acesso aos XMLs, relatórios, PDFs, balancete, busca avançada, permissões de email e mais",
        [AuthUtils.roles.ROLE_CLIENTE, AuthUtils.roles.CLIENTE, AuthUtils.roles.ORGANIZACAO]),

    GED: new RecursoKlaus("GED", "GED",
        true,
        true,
        webapps.KLAUS_WEBAPP,
        "/ged",
        "GED/ged-icon.svg",
        "Deixe avisos e mensagens com compartilhamento de arquivos",
        [AuthUtils.roles.ROLE_CLIENTE, AuthUtils.roles.CLIENTE, AuthUtils.roles.ORGANIZACAO]),

    ANALYTICS: new RecursoKlaus("Analytics", "ANALYTICS",
        true,
        true,
        webapps.KLAUS_WEBAPP,
        "/analytics",
        "analytics/analytics_icon.svg",
        "Crie análises, apure impostos, custos com fornecedores, lucros em períodos, tendências e muito mais!",
        [AuthUtils.roles.ROLE_CLIENTE, AuthUtils.roles.CLIENTE, AuthUtils.roles.ORGANIZACAO]),

    KONNECT: new RecursoKlaus("Konnect Chat", "KONNECT",
        true,
        true,
        webapps.KMSX_PWA,
        "/konnect",
        "konnect/konnect-chat.svg",
        "Troque mensagens com os seu colaboradores",
        [AuthUtils.roles.ROLE_CLIENTE, AuthUtils.roles.CLIENTE, AuthUtils.roles.ORGANIZACAO]),

    CERTIFICADO_DIGITAL: new RecursoKlaus("Certificado digital", "CERTIFICADO_DIGITAL",
        false,
        true,
        webapps.OUTROS,
        "https://klausfiscal.com.br/comprar-certificado/",
        "certificado.svg",
        "Renove seu certificado digital com a Klaus",
        [AuthUtils.roles.ADMINISTRADOR, AuthUtils.roles.ORGANIZACAO]),

    FERRAMENTAS_DO_DESENVOLVEDOR: new RecursoKlaus("Ferramentas do desenvolvedor", "FERRAMENTAS_DO_DESENVOLVEDOR",
        true,
        true,
        webapps.KLAUS_WEBAPP,
        "/dev",
        "ferramentas-dev.svg",
        null,
        [AuthUtils.roles.DESENVOLVEDOR]),

    SUPORTE: new RecursoKlaus("Suporte", "SUPORTE", false, false, webapps.OUTROS, "https://atendimento.klausfiscal.com.br/kb"),

    LISTA_DE_NFES: new RecursoKlaus("Lista de NF-es", "LISTA_DE_NFES", false, false, webapps.KLAUS_WEBAPP, "/fiscal/dfe/nfe"),
    BALANCETE: new RecursoKlaus("Balancete", "BALANCETE", false, false, webapps.KLAUS_WEBAPP, "/fiscal/balancete", null, null, [AuthUtils.roles.CLIENTE, AuthUtils.roles.ORGANIZACAO]),
    LISTA_DE_NFES: new RecursoKlaus("Lista de NF-es", "LISTA_DE_NFES", false, false, webapps.KLAUS_WEBAPP, "/fiscal/dfe/nfe", null, null, [AuthUtils.roles.CLIENTE, AuthUtils.roles.ORGANIZACAO]),
    LISTA_DE_CTES: new RecursoKlaus("Lista de CT-es", "LISTA_DE_CTES", false, false, webapps.KLAUS_WEBAPP, "/fiscal/dfe/cte", null, null, [AuthUtils.roles.CLIENTE, AuthUtils.roles.ORGANIZACAO]),
    LISTA_DE_CTES_OS: new RecursoKlaus("Lista de CT-es OS", "LISTA_DE_CTES_OS", false, false, webapps.KLAUS_WEBAPP, "/fiscal/dfe/cteos", null, null, [AuthUtils.roles.CLIENTE, AuthUtils.roles.ORGANIZACAO]),
    LISTA_DE_NFCES: new RecursoKlaus("Lista de NFC-es", "LISTA_DE_NFCES", false, false, webapps.KLAUS_WEBAPP, "/fiscal/dfe/nfce", null, null, [AuthUtils.roles.CLIENTE, AuthUtils.roles.ORGANIZACAO]),
    LISTA_DE_CFES: new RecursoKlaus("Lista de CF-es", "LISTA_DE_CFES", false, false, webapps.KLAUS_WEBAPP, "/fiscal/dfe/cfe", null, null, [AuthUtils.roles.CLIENTE, AuthUtils.roles.ORGANIZACAO]),
    LISTA_DE_NFSES: new RecursoKlaus("Lista de NFS-es", "LISTA_DE_NFSES", false, false, webapps.KLAUS_WEBAPP, "/fiscal/dfe/nfse", null, null, [AuthUtils.roles.CLIENTE, AuthUtils.roles.ORGANIZACAO]),
    GERENCIAR_MINHA_CONTA: new RecursoKlaus("Gerenciar minha conta", "GERENCIAR_MINHA_CONTA", false, false, webapps.KLAUS_WEBAPP, "/usuario/alterar", null, null, [AuthUtils.roles.CLIENTE, AuthUtils.roles.ORGANIZACAO]),

    GERENCIAR_USUARIOS: new RecursoKlaus("Gerenciar usuários", "GERENCIAR_USUARIOS", false, false, webapps.KLAUS_WEBAPP, "/usuarios", null, null, [AuthUtils.roles.ORGANIZACAO]),
    CONFIGURAR_PERMISSOES_DE_EMAIL: new RecursoKlaus("Configurar permissões de email", "CONFIGURAR_PERMISSOES_DE_EMAIL", false, false, webapps.KLAUS_WEBAPP, "/usuario/alterar/permissoes-email", null, null, [AuthUtils.roles.ORGANIZACAO]),
    GERENCIAR_PARTICIPANTES: new RecursoKlaus("Gerenciar participantes", "GERENCIAR_PARTICIPANTES", false, false, webapps.KLAUS_WEBAPP, "/fiscal/participantes", null, null, [AuthUtils.roles.ORGANIZACAO, AuthUtils.roles.USUARIO_ADM]),
    MARCACOES: new RecursoKlaus("Marcações", "MARCACOES", false, false, webapps.KLAUS_WEBAPP, "/fiscal/tags", null, null, [AuthUtils.roles.ORGANIZACAO]),
    ANALISE_DE_USO_DA_NUVEM: new RecursoKlaus("Análise de uso da nuvem", "ANALISE_DE_USO_DA_NUVEM", false, false, webapps.KLAUS_WEBAPP, "/nuvem/analiseDeUso", null, null, [AuthUtils.roles.ORGANIZACAO]),

    GERENCIAR_ORGANIZAÇÕES: new RecursoKlaus("Gerenciar organizações", "GERENCIAR_ORGANIZAÇÕES", false, false, webapps.KLAUS_WEBAPP, "/organizacoes", null, null, [AuthUtils.roles.ADMINISTRADOR]),
    GERENCIAR_DESENVOLVEDORES: new RecursoKlaus("Gerenciar desenvolvedores", "GERENCIAR_DESENVOLVEDORES", false, false, webapps.KLAUS_WEBAPP, "/desenvolvedores", null, null, [AuthUtils.roles.ADMINISTRADOR]),
    RELATORIOS: new RecursoKlaus("Relatórios", "RELATORIOS", false, false, webapps.KLAUS_WEBAPP, "/reports/admin", null, null, [AuthUtils.roles.ADMINISTRADOR]),

    LOGS: new RecursoKlaus("Logs", "LOGS", false, false, webapps.KLAUS_WEBAPP, "/dev/logs", null, null, [AuthUtils.roles.DESENVOLVEDOR]),
    ATUALIZACOES: new RecursoKlaus("Atualizações", "ATUALIZACOES", false, false, webapps.KLAUS_WEBAPP, "/dev/atualizacoes", null, null, [AuthUtils.roles.DESENVOLVEDOR]),
    GESTAO_DE_AVISOS: new RecursoKlaus("Gestão de avisos", "GESTAO_DE_AVISOS", false, false, webapps.KLAUS_WEBAPP, "/dev/avisos", null, null, [AuthUtils.roles.DESENVOLVEDOR]),


    KONNECT_MENSAGENS: new RecursoKlaus("Konnect Mensagens", "KONNECT_MENSAGENS", false, false, webapps.KMSX_PWA, "/konnect/mensagens", null, null, [AuthUtils.roles.CLIENTE, AuthUtils.roles.ORGANIZACAO]),
    KONNECT_CALENDARIO: new RecursoKlaus("Konnect Calendario", "KONNECT_CALENDARIO", false, false, webapps.KMSX_PWA, "/konnect/calendario", null, null, [AuthUtils.roles.CLIENTE, AuthUtils.roles.ORGANIZACAO]),
}

export default Object.values(recursos);
export { recursos, webapps };
