import tipos from '@/store/tipos'

export default {
    state: {
        paginaCarregando: true
    },
    mutations: {
        [tipos.PAGINA_CARREGANDO](state, paginaCarregando) {
            state.paginaCarregando = paginaCarregando;
        }
    },
    actions: {
        [tipos.PAGINA_CARREGANDO]({ commit }, paginaCarregando) {
            commit(tipos.PAGINA_CARREGANDO, paginaCarregando);
        }
    },
    getters: {
        isPaginaCarregando(state) {
            return state.paginaCarregando;
        },
    }
}