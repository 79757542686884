import Loading from "@/store/modules/Loading";
import Chat from "@/store/modules/Chat";
import KMSX from "@/store/modules/KMSX";
import Vuex from 'vuex'
import Vue from 'vue'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    Loading,
    KMSX,
    Chat,
  },
})
