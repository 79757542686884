import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

// Importa o pacote do font awesome
import "@/assets/fontawesome/css/fontawesome.css";
import "@/assets/fontawesome/css/fa-solid.css";

// Importa o ElementUI
import elementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "element-ui/lib/theme-chalk/display.css";
import locale from "element-ui/lib/locale/lang/pt-br";

Vue.config.productionTip = false
Vue.use(elementUI, { locale });

Notification.requestPermission();



Vue.directive("click-outside", {
  bind(el, binding, vnode) {
    el.clickOutsideEvent = function(event) {
      // here I check that click was outside the el and his childrens
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unbind(el) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
