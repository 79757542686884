import KWDDPHelper from "@/websockets/KWDDPHelper.js";
import AuthUtils from "@/utils/AuthUtils.js";
import tipos from '@/store/tipos'
import moment from "moment";
import Vue from 'vue'

export default {
    state: {
        usuariosOnline: {},
        usuariosOffline: {},
    },
    mutations: {
        [tipos.KMSX_CLEAR](state) {
            Vue.delete(state, 'usuariosOnline')
            Vue.delete(state, 'usuariosOffline')

            Vue.set(state, 'usuariosOnline', {})
            Vue.set(state, 'usuariosOffline', {})
        },
        [tipos.KMSX_ADICIONAR_TODOS_USUARIOS_ONLINE](state, usuariosOnline) {
            // Resta a propriedade
            state.usuariosOnline = {}

            // Junta todos os ids (online e offline). Considera-se que apenas os offlines estão
            // sendo atualizados. Utiliza Set pra evitar valores repetidos
            const mergeKeys = [...new Set([...(Object.keys(state.usuariosOffline)), ...(Object.keys(usuariosOnline))])];

            // Vai para cada Key
            mergeKeys.forEach(k => {
                // Tenta recuperar o objeto em cada uma das listas
                const objOnline = usuariosOnline[k];
                const objOffline = state.usuariosOffline[k];

                // Indica se o usuário está online
                const isUsuarioOnline = !!objOnline;

                // Se o usuário estiver online...
                if (isUsuarioOnline) {
                    // Faz o merge dos objetos
                    const objFinal = { ...objOffline, ...objOnline }

                    // Remove e adiciona novamente, na lista de usuários ONLINE
                    Vue.delete(state.usuariosOnline, k);
                    Vue.set(state.usuariosOnline, k, objFinal)

                    // Deleta da lista de usuários OFFLINE
                    Vue.delete(state.usuariosOffline, k);
                }
            });
        },
        [tipos.KMSX_ADICIONAR_DEVICE_ONLINE](state, conexaoDevice) {
            // Se o device já existir, já retorna
            if (!state.usuariosOnline[conexaoDevice.usuario.id] || state.usuariosOnline[conexaoDevice.usuario.id].devices.findIndex(d => d.deviceId === conexaoDevice.device.deviceId) >= 0) return;

            // Adiciona o device na lista do usuário
            state.usuariosOnline[conexaoDevice.usuario.id].devices.push(conexaoDevice.device)
        },
        [tipos.KMSX_REMOVER_DEVICE_ONLINE](state, desconexaoDevice) {
            // Recupera o usuário que teve desconexão através do id
            if (state.usuariosOnline[desconexaoDevice.usuario.id]) {
                // Recupera o index do device desconetado
                const index = state.usuariosOnline[desconexaoDevice.usuario.id].devices.findIndex(d => d.deviceId === desconexaoDevice.device.deviceId)

                // Se for menor que 0 (zero) significa qe não foi encontrado, então só retorna
                if (index < 0) return;

                // Remove o device
                state.usuariosOnline[desconexaoDevice.usuario.id].devices.splice(index, 1)
            }
        },
        [tipos.KMSX_ADICIONAR_USUARIO_ONLINE](state, novoUsuario) {
            // Se o usuário já existir, já retorna
            if (state.usuariosOnline[novoUsuario.id]) return;

            // Junta os dois objetos
            const merge = { ...state.usuariosOffline[novoUsuario.id], ...novoUsuario }

            // Se for undefined, já adiciona o novo usuário na lista de online :)
            Vue.set(state.usuariosOnline, novoUsuario.id, merge)

            // E Remove da lista de offline
            Vue.delete(state.usuariosOffline, novoUsuario.id);
        },
        [tipos.KMSX_REMOVER_USUARIO_ONLINE](state, desconexaoUsuario) {
            // Junta os dois objetos
            const merge = { ...state.usuariosOnline[desconexaoUsuario.id], ...desconexaoUsuario }

            // Deleta a lista de devices
            delete merge.devices

            // Se for undefined, já adiciona o novo usuário na lista de offline
            Vue.set(state.usuariosOffline, desconexaoUsuario.id, merge)

            // E Remove da lista de online
            Vue.delete(state.usuariosOnline, desconexaoUsuario.id);
        },
        [tipos.KMSX_ATUALIZAR_TODOS_USUARIOS_OFFLINE](state, usuariosOffline) {
            // Resta a propriedade
            state.usuariosOffline = {}

            // Junta todos os ids (online e offline). Considera-se que apenas os offlines estão
            // sendo atualizados. Utiliza Set pra evitar valores repetidos
            const mergeKeys = [...new Set([...(Object.keys(state.usuariosOnline)), ...(Object.keys(usuariosOffline))])];

            // Vai para cada Key
            mergeKeys.forEach(k => {
                // Tenta recuperar o objeto em cada uma das listas
                const objOnline = state.usuariosOnline[k];
                const objOffline = usuariosOffline[k];

                /* ********* Processa informações ********* */
                // Declara objeto que guardará o usuário offline processado
                const usuarioData = {}

                // Declara objeto que guardará as informações de documentos fiscais processadas
                const docsData = { atividadesRecentes: {}, ultimaAtividade: { tipo: KWDDPHelper.getTiposDeUltimaAtividade().nenhumaAtividadeRealizada } }
                for (const p in objOffline) {
                    switch (p) {
                        case "_id":
                            usuarioData["id"] = objOffline._id
                            break;
                        case "organizacaoId":
                            usuarioData["organizacaoId"] = objOffline.organizacaoId
                            break;
                        case "usuarioNome":
                            usuarioData["nome"] = objOffline.usuarioNome
                            break;
                        case "usuarioRoles":
                            usuarioData["roles"] = objOffline.usuarioRoles
                            break;
                        default:
                            // Se não foi uma informação conhecida, considera como atividadesRecentes...
                            // Se for nulo, ignora
                            if (!objOffline[p]) {
                                docsData.atividadesRecentes[p] = {};
                                break;
                            }

                            // Guarda valor
                            docsData.atividadesRecentes[p] = objOffline[p]

                            // E calcula qual é a ATIVIDADE MAIS RECENTE DE TODAS
                            // Muda de date (string) para moment
                            const date = moment(objOffline[p].ultimaSincronizacao.date)
                            objOffline[p].ultimaSincronizacao.date = date;

                            // Guarda o mais novo
                            if (!docsData.ultimaAtividade.date || date.isSameOrAfter(docsData.ultimaAtividade.date)) {
                                docsData.ultimaAtividade.tipo = KWDDPHelper.getTiposDeUltimaAtividade().sincronizacaoDeDocumentosFiscais;
                                docsData.ultimaAtividade.sistemaOperacional = objOffline[p].ultimaSincronizacao.sistemaOperacional
                                docsData.ultimaAtividade.aplicativo = objOffline[p].ultimaSincronizacao.aplicativo
                                docsData.ultimaAtividade.date = date;
                                docsData.ultimaAtividade.doc = p;
                            }

                            // Finaliza
                            break;
                    }
                }

                // Indica se o usuário está online
                const isUsuarioOnline = !!objOnline;


                if (isUsuarioOnline) {
                    // Se o usuário estiver online...
                    // Faz o merge dos objetos
                    const objFinal = { ...usuarioData, ...objOnline, ...docsData }

                    // Remove e adiciona novamente, na lista de usuários ONLINE
                    Vue.delete(state.usuariosOnline, k);
                    Vue.set(state.usuariosOnline, k, objFinal)

                    // Deleta da lista de usuários OFFLINE
                    Vue.delete(state.usuariosOffline, k);
                } else {
                    // Se o usuário estiver offline...
                    // Faz o merge dos objetos
                    const objFinal = { ...usuarioData, ...docsData }

                    // Remove e adiciona novamente, na lista de usuários OFFLINE
                    Vue.delete(state.usuariosOffline, k);
                    Vue.set(state.usuariosOffline, k, objFinal)

                    // Deleta da lista de usuários ONLINE
                    Vue.delete(state.usuariosOnline, k);
                }
            });
        },
    },
    actions: {
        [tipos.KMSX_CLEAR]({ commit }) {
            commit(tipos.KMSX_CLEAR)
        },
        [tipos.KMSX_ADICIONAR_TODOS_USUARIOS_ONLINE]({ commit }, usuariosOnline) {
            return new Promise((resolve, reject) => {
                commit(tipos.KMSX_ADICIONAR_TODOS_USUARIOS_ONLINE, usuariosOnline)
                resolve(usuariosOnline)
            })
        },
        [tipos.KMSX_ADICIONAR_DEVICE_ONLINE]({ commit }, conexaoDevice) {
            return new Promise((resolve, reject) => {
                commit(tipos.KMSX_ADICIONAR_DEVICE_ONLINE, conexaoDevice)
                resolve(conexaoDevice)
            })
        },
        [tipos.KMSX_REMOVER_DEVICE_ONLINE]({ commit }, desconexaoDevice) {
            return new Promise((resolve, reject) => {
                commit(tipos.KMSX_REMOVER_DEVICE_ONLINE, desconexaoDevice)
                resolve(desconexaoDevice)
            })
        },
        [tipos.KMSX_ADICIONAR_USUARIO_ONLINE]({ commit }, novoUsuario) {
            return new Promise((resolve, reject) => {
                commit(tipos.KMSX_ADICIONAR_USUARIO_ONLINE, novoUsuario)
                resolve(novoUsuario)
            })
        },
        [tipos.KMSX_REMOVER_USUARIO_ONLINE]({ commit }, desconexaoUsuario) {
            return new Promise((resolve, reject) => {
                commit(tipos.KMSX_REMOVER_USUARIO_ONLINE, desconexaoUsuario)
                resolve(desconexaoUsuario)
            })
        },
        [tipos.KMSX_ATUALIZAR_TODOS_USUARIOS_OFFLINE]({ commit }, todosUsuariosDaOrg) {
            return new Promise((resolve, reject) => {
                commit(tipos.KMSX_ATUALIZAR_TODOS_USUARIOS_OFFLINE, todosUsuariosDaOrg)
                resolve()
            })
        },
    },
    getters: {
        getUsuariosOnline(state) {
            return state.usuariosOnline;
        },
        getOutrosUsuariosOnlineAsArray(state) {
            return Object.keys(state.usuariosOnline).filter(id => id !== ("" + AuthUtils.getUser().id)).map(id => state.usuariosOnline[id])
        },
        getApenasUsuarioLogado(state) {
            return state.usuariosOnline[AuthUtils.getUser().id]
        },
        getUsuariosOffline(state) {
            return state.usuariosOffline;
        },
        getOutrosUsuariosOfflineAsArray(state) {
            return Object.keys(state.usuariosOffline).map(id => state.usuariosOffline[id])
        },
    }
}