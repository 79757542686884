import { KMSXWebsocket, Ambientes, Device, KWDDP, AplicativosSuportados, KWDDPReceiversActions } from "kmsx-js-client"
import { addMessageHandlerWithValidations } from "@/websockets/MessageHandlerHelper";
import AuthUtils from "@/utils/AuthUtils"
import eventBus from "@/eventBus"

KMSXWebsocket.setAmbiente(Ambientes[process.env.VUE_APP_WEBSOCKET_AMBIENTE_MODO])
//KMSXWebsocket.setAmbiente(Ambientes.DESENVOLVIMENTO)

// Guarda a key do UUID no local storage
const KEY_DEVICE_UUID = "deviceUUID"

const podeConectarAoKWDDP = () => {
    return (
        !AuthUtils.isRole(AuthUtils.roles.ADMINISTRADOR)
        &&
        !AuthUtils.isRole(AuthUtils.roles.DESENVOLVEDOR)
        &&
        !AuthUtils.isRole(AuthUtils.roles.USUARIO_LIMITADO)
    )
}

/**
 * Formatador padrão de datas
 * @param {*} date Date
 * @returns Date em string
 */
const formatDate = (date) => {
    return date.format("DD/MM/YYYY [às] HH:mm");
};

const getDocFiscal = (dfe) => {
    switch (dfe) {
        case "nfe":
            return "NF-e";
        case "cte":
            return "CT-e";
        case "cteos":
            return "CT-e OS";
        case "cfe":
            return "CF-e";
        case "nfse":
            return "NFS-e";
        default:
            return dfe;
    }
};

export default {
    podeConectarAoKWDDP,
    iniciarConexao() {
        // Se o usuário nem estiver logado OU já se ele já estiver conectado no websocket, já retorna
        if (AuthUtils.getUser() === null || KMSXWebsocket.cannotConnect()) return;

        // Recupera o UUID do local storage
        let uuid = window.localStorage.getItem(KEY_DEVICE_UUID);

        // Se for nulo
        if (uuid === null) {
            // Gera um novo
            uuid = Device.generateRandomUUID()

            // Adiciona no local storage
            window.localStorage.setItem(KEY_DEVICE_UUID, uuid)
        }

        this.addDefaultMsgHandlers();

        // Tenta conectar ao servidor
        KMSXWebsocket.conectar(
            new KWDDP(
                AuthUtils.getToken(),
                uuid,
                AplicativosSuportados.KLAUS_KONNECT_PWA
            )
        );
    },

    finalizarConexao() {
        // Se não puder desconectar, finaliza
        if (KMSXWebsocket.cannotDisconnect()) return;

        // Tenta desconectar do servidor
        KMSXWebsocket.desconectar()
    },


    addDefaultMsgHandlers() {
        KMSXWebsocket.setOnOkHandler(() => eventBus.$emit("tratarConexaoWebsocket", "Ok"))
        KMSXWebsocket.setOnRetryingHandler(() => eventBus.$emit("tratarConexaoWebsocket", "Retrying"))
        KMSXWebsocket.setOnFailedHandler(() => eventBus.$emit("tratarConexaoWebsocket", "Failed"))

        // Canal
        addMessageHandlerWithValidations(KWDDPReceiversActions.KONNECT_ENTERPRISE_CANAIS_NOVO, "konnect-enterprise-canais-novo", async (obj, isStatusRepostaPostivo, message) => {
            eventBus.$emit("novoCanal", obj.data)
        });
        addMessageHandlerWithValidations(KWDDPReceiversActions.KONNECT_ENTERPRISE_CANAIS_ALTERADO, "konnect-enterprise-canais-alterado", async (obj, isStatusRepostaPostivo, message) => {
            eventBus.$emit("alteradoCanal", obj.data)
        });
        addMessageHandlerWithValidations(KWDDPReceiversActions.KONNECT_ENTERPRISE_CANAIS_DELETADO, "konnect-enterprise-canais-deletado", async (obj, isStatusRepostaPostivo, message) => { 
            eventBus.$emit("deletarCanal", obj.data)
        });

        // Canal.Membros
        addMessageHandlerWithValidations(KWDDPReceiversActions.KONNECT_ENTERPRISE_CANAIS_MEMBROS_NOVO, "konnect-enterprise-canais-membros-novo", async (obj, isStatusRepostaPostivo, message) => {
            eventBus.$emit("novoMembro", obj.data)
        });

        addMessageHandlerWithValidations(KWDDPReceiversActions.KONNECT_ENTERPRISE_CANAIS_MEMBROS_ALTERADO, "konnect-enterprise-canais-membros-alterado", async (obj, isStatusRepostaPostivo, message) => {
            eventBus.$emit("alteradoMembro", obj.data)
        });

        addMessageHandlerWithValidations(KWDDPReceiversActions.KONNECT_ENTERPRISE_CANAIS_MEMBROS_DELETADO, "konnect-enterprise-canais-membros-deletado", async (obj, isStatusRepostaPostivo, message) => {
            eventBus.$emit("deletarMembro", obj.data)
        });

        // Canal.Mensagens
        addMessageHandlerWithValidations(KWDDPReceiversActions.KONNECT_ENTERPRISE_CANAIS_MENSAGENS_NOVA, "konnect-enterprise-canais-mensagem-nova", async (obj, isStatusRepostaPostivo, message) => {
            eventBus.$emit("addNotificacao", obj.data)
            eventBus.$emit("novaMensagem", obj.data)
        });
        addMessageHandlerWithValidations(KWDDPReceiversActions.KONNECT_ENTERPRISE_CANAIS_MENSAGENS_DELETADA, "konnect-enterprise-canais-mensagem-deletada", async (obj, isStatusRepostaPostivo, message) => {
            eventBus.$emit("deletarMensagem", obj.data)
        });
    },

    // ------------------------------------------------------------------------------------
    // Front Helpers
    // ------------------------------------------------------------------------------------
    getTiposDeUltimaAtividade() {
        return tiposDeUltimaAtividade
    },

    getAppPresentationName(appIdentifier) {
        return TodosAplicativos.getAppByIdentifier(appIdentifier)
            .presentationName;
    },

    getOSPresentationName(osIdentifier) {
        return TodosSistemasOperacionais.getOSByIdentifier(osIdentifier)
            .presentationName;
    },
    isUltimaAtvNenhuma(usuario) {
        return isUltimaAtvDoTipo(
            usuario,
            tiposDeUltimaAtividade.nenhumaAtividadeRealizada
        );
    },
    isUltimaAtvSincDfe(usuario) {
        return isUltimaAtvDoTipo(
            usuario,
            tiposDeUltimaAtividade.sincronizacaoDeDocumentosFiscais
        );
    },
    getDocFiscalUltimaAtvDate(usuario) {
        return getDocFiscal(usuario.ultimaAtividade.doc);
    },
    formatUltimaAtvDate(usuario) {
        return formatDate(usuario.ultimaAtividade.date);
    },
    formatDate,
    getDocFiscal,
}