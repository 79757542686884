import AuthService from "@/services/AuthService";
import KonnectManagerService from "@/services/KonnectManagerService";

const USUARIO_NAME_LOCALSTORAGE = "usuario";

const AuthUtils = {
  getToken() {
    const tokenLocalStorage = window.localStorage.getItem("token")
    return tokenLocalStorage ? tokenLocalStorage : ''
  },

  getUser() {
    return JSON.parse(window.localStorage.getItem(USUARIO_NAME_LOCALSTORAGE))
  },

  isRole(role) {
    let roles = AuthUtils?.getUser()?.roles != undefined ? AuthUtils?.getUser()?.roles : []
    return role === AuthUtils?.getUser()?.tipoUsuario || roles?.includes(role)
  },

  /**
   * Se não houver o usuário logado no local storage, tentar fazer a requisição pro backend
   * E caso a requisição não seja bem sucedida faz o logout do usuário
   */
  async validarUsuarioLogado() {
    // Se o usuário não existir no localstorage
    if (!window.localStorage.getItem(USUARIO_NAME_LOCALSTORAGE)) {
      // Tenta...
      try {
        // Fazer a requisição para o servidor e recuperar o usuário logado
        const response = await AuthService.usuarioLogado();

        // Verifica se o usuário tem acesso ao Konnect
        const responseKonnect = KonnectManagerService.permissaoUsuarioLogado();

        // Atualiza o local storage
        window.localStorage.setItem(
          USUARIO_NAME_LOCALSTORAGE,
          JSON.stringify(response.data)
        );
      } catch (error) {
        // Caso de erro, faz o logout
        this.doLogout();
      }
    }
  },

  doLogout(){
    AuthService.logout();
  },


  roles: {
    ADMINISTRADOR: 'ADMINISTRADOR',
    ORGANIZACAO: 'ROLE_ORGANIZACAO',
    CLIENTE: 'CLIENTEDEORGANIZACAO',
    ROLE_CLIENTE: 'ROLE_CLIENTE',
    DESENVOLVEDOR: 'DESENVOLVEDOR',
    USUARIO_ADM: 'ROLE_CLIENTE_ADMINISTRADOR',
    USUARIO_LIMITADO: 'ROLE_CLIENTE_LIMITADO'
  }
}

export default AuthUtils
