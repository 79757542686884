import { KMSXWebsocket } from "kmsx-js-client";
import { Notification } from "element-ui";

/**
 * @typedef {import('kmsx-js-client/src/KMSXWebsocket').WebsocketRetornoJson} WebsocketRetornoJson
 */
/**
 * @typedef {import('kmsx-js-client/src/KMSXWebsocket').MessageHandlerFunction} MessageHandlerFunction
 */

/**
* Adicione um handler com handlers de erros para uma mensagem recebida
* 
* @param {string} action Action que deve fazer o handler
* @param {string} identifier Nome do handler (Deve ser único. Se repetido: irá substituir o que já está)
* @param {MessageHandlerFunction} handlerFunction Função que será executada quando chegar a mensagem
*/
export const addMessageHandlerWithValidations = (action, identifier, handlerFunction) => {
    KMSXWebsocket.addMessageHandler(action, identifier, async (obj, isStatusRepostaPostivo, message) => {
        if (isStatusRepostaPostivo) {
            try {
                await handlerFunction(obj, isStatusRepostaPostivo, message);
            } catch (error) {
                Notification.warning({
                    title: "Erro no recebimento de informações",
                    message: `Erro: ${error}`,
                });
            }
        } else {
            Notification.warning({
                title: "Erro no recebimento de informações",
                message: `Erro: ${obj.status}`,
            });
        }
    });
}