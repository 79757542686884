import AuthUtils from "@/utils/AuthUtils.js";
import AuthService from "../services/AuthService";
import VueRouter from 'vue-router'
import store from "@/store/index"
import Vue from 'vue'

Vue.use(VueRouter)

const prefixo = '/konnect'
var roles = AuthUtils.roles;
const TOKEN_RENOVAR_ROUTE_NAME = "renovarToken";

function isMobile() {
  if( screen.width <= 768 ) {
      return true;
  }
  else {
      return false;
  }
}

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ "@/components/Login.vue"),
  },
  {
    path: '/',
    redirect: `${prefixo}/mensagens`,
    meta: {
      auth: true,
      roles: [
        roles.CLIENTE,
        roles.ORGANIZACAO,
        roles.USUARIO_LIMITADO,
        roles.USUARIO_ADM,
      ]
    }
  },
  {
    path: `${prefixo}/mensagens`,
    name: 'Mensagens',
    component: () => import(/* webpackChunkName: "msgs" */ "@/components/Mensagens.vue"),
    meta: {
      auth: true,
      roles: [
        roles.CLIENTE,
        roles.ORGANIZACAO,
        roles.USUARIO_LIMITADO,
        roles.USUARIO_ADM,
      ]
    }, 
    children: [
      {
        path: 'inicio',
        name: 'TelaInicial',
        component: () => import(/* webpackChunkName: "msgs" */ "@/components/TelaInicial.vue"),   
      },
      {
        path: 'chat',
        name: 'Chat',
        component: () => import(/* webpackChunkName: "msgs" */ "@/components/Chat.vue"),   
      },
      {
        path: 'info',
        name: 'Info',
        component: () => import(/* webpackChunkName: "msgs" */ "@/components/InfoChat.vue"),
      },
    ]
  },
  {
    path: `${prefixo}/calendario`,
    name: 'Calendario',
    component: () => import(/* webpackChunkName: "calendario" */ "@/components/Calendario.vue"),
    meta: {
      auth: true,
      roles: [
        roles.CLIENTE,
        roles.ORGANIZACAO,
        roles.USUARIO_LIMITADO,
        roles.USUARIO_ADM,
      ]
    }
  },
  {
    path: `${prefixo}/token/renovar`,
    name: TOKEN_RENOVAR_ROUTE_NAME,
    component: () => import(/* webpackChunkName: "renovar-token" */ "@/components/RenovarToken/RenovarToken.vue"),
    props: true,
    meta: {}
  },
  {
    path: "*",
    name: "404",
    component: () =>
      import(
        /* webpackChunkName: "pagina-nao-encontrada" */ "@/components/PaginaNaoEncontrada/PaginaNaoEncontrada"
      )
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// Faz a validação para saber se o usuário está logado
router.beforeEach(async (to, from, next) => {
  // Seta a tela de loading
  store.dispatch("paginaCarregando", true);

  // Filtra
  let user = AuthUtils.getUser();
  if (to.meta != null) {
    //validação STL
    
    if (to.meta.stl) {
      
      // Recupera o nível atual de stl do usuário
      const nivelAtual = ( await AuthService.getStl()).data.stl
      // Se o nível atual for menor que o mínimo exigido pela rota, encaminha para a tela de renovação de token
      if (nivelAtual < to.meta.stl) return next({
        name: TOKEN_RENOVAR_ROUTE_NAME,
        params: {
          nextPage: to,
        }
      })
    }

    if (to.meta.auth) {
      if (user !== null) {
        let found = false;
        for (let i = 0; i < to.meta.roles.length; i++) {
          if (AuthUtils.isRole(to.meta.roles[i])) {
            found = true;
          }
        }
        if (found) {
          next();
        } else {      
          next("/login");
        }
      } else {
        next("/login");
      }
    } else {
      if (to.name === "login") {
        if (user !== null) {
            next("/");
        }
      }
      next();
    }
  } else {
    next();
  }
});

router.afterEach((to, from) => {
  store.dispatch("paginaCarregando", false);
});

export default router
