import KFisServiceAdapterV1 from '@/services/KFisServiceAdapterV1.js'
import KFisServiceAdapterV2 from '@/services/KFisServiceAdapterV2.js'
import KWDDPHelper from "../websockets/KWDDPHelper";

const AuthService = {
  login: (usuario) => {
    return KFisServiceAdapterV2.post('authentication/usuario/tokens/emitir/comEmailESenha', usuario)
  },

  usuarioTokenRenovar: (usuario) => {
    return KFisServiceAdapterV2.post('authentication/usuario/tokens/renovar', usuario, {
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + window.localStorage.getItem("token")
      }
    })
  },

  logout: () => {
    window.localStorage.clear();
    KWDDPHelper.finalizarConexao();
  },

  usuarioLogado: () => {
    return KFisServiceAdapterV1.get('usuarios/eu', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + window.localStorage.getItem("token")
      }
    })
  },
  getStl: () =>{
    return KFisServiceAdapterV2.get('authentication/stl',{
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': 'Bearer ' + window.localStorage.getItem("token")
      }
    })
  },
}

export default AuthService